onmount = require("onmount");

onmount(".js-ensure-valid-booking-attempt", function () {
  let form = this;
  form.addEventListener("submit", function (event) {
    if (
      window.bookingAttemptStatusCheckUrl != undefined &&
      window.redirectOnTimeoutUrl != undefined
    ) {
      $.get({
        url: window.bookingAttemptStatusCheckUrl,
        dataType: "json",
        async: false,
        success: (data) => {
          if (
            data["status"] === "completed" ||
            data["status"] === "abandoned"
          ) {
            window.location = window.redirectOnTimeoutUrl;
            event.stopPropagation();
            event.preventDefault();
          } else if (data["status"] === "booking_full") {
            window.location = window.redirectOnBookingFullUrl;
            event.stopPropagation();
            event.preventDefault();
          }
        },
      });
    }
  });
});
