$(document).on("turbolinks:load", function () {
  if ($('#js-online-booking-boarding-time-select').is('*')) {
    loadCalendar();
  }
});

function loadCalendar(){
  var url = $('.datepicker-boarding').data('url');
  var service_id = $('#booking_service_id').val();
  var location_id = $('#selected_location_id').val();
  var submit_button = $('#booking-btn');

  if ($('.datepicker-boarding').data('dropoff') == true) {
    var mode = 'single'
  } else {
    var mode = 'range'
  }

  window.datePickerBoardingAvailableDates = []
  flatpickr(".datepicker-boarding", {
    autoclose: true,
    inline: true,
    mode: mode,
    monthSelectorType: "static",
    dateFormat: "D d M Y",
    allowInput: true,
    onReady: function(dates, dateStr, calendar) {
      getDates(calendar, url, service_id, location_id);
      // Recalc if pet number changes
      $('#js-number-of-pets').on('change keyup', function(){
        getDates(calendar, url, service_id, location_id);
      });
    },
    onMonthChange: function(dates) {
      // Get new enabled dates if the calendar advances months
      getDates(this, url, service_id, location_id);
    },
    onYearChange: function(dates) {
      // Get new enabled dates if the calendar advances years
      getDates(this, url, service_id, location_id);
    },
    onChange: function(dates, dateStr, calendarInstance) {

      if (mode == 'range') {

        if(dates.length == 2) {
          var allDaysAreAvailable = true;
          var startDate = moment(dates[0]);
          var endDate = moment(dates[1]);

          var allDates = []

          while(startDate.format('M/D/YYYY') !== endDate.format('M/D/YYYY')) {
            allDates.push(startDate.toDate());
            startDate = startDate.add(1, 'days');
          }

          $(allDates).each(function() {
            if(!calendarInstance.isEnabled(this)) {
              allDaysAreAvailable = false;
            }
          });
        }

        if (dates.length == 2 && dates[0].toString() === dates[1].toString()) {
          $('#js-error-no-space').html('Check in and Check out cannot be on the same day').removeClass('d-none p-3')
          submit_button.prop('disabled', true);
        } else if (dates.length == 2 && !allDaysAreAvailable) {
          calendarInstance.clear();

          setTimeout(function () {
              $('#js-error-no-space').html('Not all the days you selected are available. Please select a range that has consecutive available days.').removeClass('d-none p-3')
                submit_button.prop('disabled', true);
            },250
          );
        } else if (dates.length == 2 && dates.length == 2) {
          $('#js-error-no-space').addClass('d-none');
          submit_button.prop('disabled', false);
        } else {
          $('#js-error-no-space').addClass('d-none');
          submit_button.prop('disabled', true);
        }

      } else {

        var duration = $('.datepicker-boarding').data('duration');
        submit_button.prop('disabled', false);

        var end_date = new Date(dates);

        end_date.setDate(end_date.getDate() + duration);

        var displayOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        $('#js-pickup-date span').text(end_date.toLocaleDateString("en-US", displayOptions));
        $('#js-pickup-date').removeClass('d-none');
      }
    }
  });
}

function getDates(calendar, url, service_id, location_id, date) {
  var petCount = $('#js-number-of-pets').val();

  if (!petCount) {
    console.log('No pets entered');
    return
  }

  if (!date) {
    var date = '01-'+ (calendar.currentMonth + 1) +'-'+ calendar.currentYear
  }

  $.ajax({
    url: url,
    type: "GET",
    dataType: "script",
    data: { service_id: service_id, location_id: location_id, pets: petCount, start_date: date },
    success: (response) => {
      var json = JSON.parse(response);

      var start_date = json.start_date;
      var end_date = json.end_date;

      // Note: we keep this window.datePickerBoardingAvailableDates for pagination related issues.
      // Remove anyting between start and end date being searched for
      if(start_date != undefined && end_date != undefined) {
        start_date = Date.parse(start_date);
        end_date = Date.parse(end_date);

        var datesToRemove = []
        $(window.datePickerBoardingAvailableDates).each(function(){
          var filteredDate = Date.parse(this)

          if(filteredDate > start_date && filteredDate < end_date){
            datesToRemove.push(this);
          }
        });

        window.datePickerBoardingAvailableDates = window.datePickerBoardingAvailableDates.filter( item => datesToRemove.includes(item));
      }

      // Rebuild with new dates between the times
      window.datePickerBoardingAvailableDates.push(json.available_dates);
      window.datePickerBoardingAvailableDates = window.datePickerBoardingAvailableDates.flat(1);

      if (window.datePickerBoardingAvailableDates.length == 0) {
        // Display error message
        calendar.set('enable', [function() { false }]);
        $('#js-error-no-space').removeClass('d-none');
      } else {
        calendar.set('enable', window.datePickerBoardingAvailableDates);
        $('#js-error-no-space').addClass('d-none');
      }
    }
  });
}
