$(document).on("turbolinks:load", function () {

  if ($('.specific_booking_slot').is('*')) {
    $('.specific_booking_slot a').addClass('active');

    // $("body").on("click", ".specific_booking_slot a ", function(event) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // });
  }
 });

