$(document).on("turbolinks:load", function () {

  if ($('#js-select-addons').is('*')) {
    $('.js-addon-btn').on('click', function (event){
      event.preventDefault();

      var addon_ids = JSON.parse($('#js-addon-ids').val())

      if($(this).hasClass('btn-outline-dark')){
        // Active
        $(this).removeClass('btn-outline-dark');
        $(this).addClass('btn-primary');

        addon_ids.push($(this).data('addonId'))
      } else {
        $(this).removeClass('btn-primary');
        $(this).addClass('btn-outline-dark');

        var index = addon_ids.indexOf($(this).data('addonId'));

        if (index !== -1) {
          addon_ids.splice(index, 1);
        }
      }

      $('#js-addon-ids').val(JSON.stringify(addon_ids));

      if(addon_ids.length < 1 ) {
        $('#addon-selected-count').hide();
        $('#btn-addon-continue').html('Continue without Add-on');
      } else if(addon_ids.length === 1) {
        $('#addon-selected-count').fadeIn();
        $('#addon-selected-count .count').html('1 Add-on selected');
        $('#btn-addon-continue').html('Continue to Purchase');
      } else {
        $('#addon-selected-count').fadeIn();
        $('#addon-selected-count .count').html(addon_ids.length + ' Add-ons selected');
        $('#btn-addon-continue').html('Continue to Purchase');
      }
    });
  }

  if ($('.js-select-addons-with-duration').is('*')) {
    $('.js-addon-btn').on('click', function (event){
      event.preventDefault();

      var scope = $('.js-select-addons-with-duration:visible');

      if(scope.data('addonIds') === undefined) {
        scope.data('addonIds', '[]');
      }

      var addon_ids = JSON.parse(scope.data('addonIds'));

      if($(this).hasClass('btn-outline-dark')){
        // Active
        $(this).removeClass('btn-outline-dark');
        $(this).addClass('btn-primary');

        addon_ids.push($(this).data('addonId'))
      } else {
        $(this).removeClass('btn-primary');
        $(this).addClass('btn-outline-dark');

        var index = addon_ids.indexOf($(this).data('addonId'));

        if (index !== -1) {
          addon_ids.splice(index, 1);
        }
      }

      scope.data('addonIds', JSON.stringify(addon_ids));

      totalMinutes = 0;

      addon_ids.forEach(function(addonId){
        totalMinutes += scope.find("[data-addon-id='" + addonId + "']").data('durationMinutes')
      });

      if(addon_ids.length < 1 ) {
        scope.find('.js-addon-selected-count:visible ').hide();
        $('.js-btn-addon-continue:visible').html('Book Now');
      } else if(addon_ids.length === 1) {
        scope.find('.js-addon-selected-count').fadeIn();
        scope.find('.js-addon-selected-count .count').html('1 Add-on selected');
        scope.find('.js-addon-selected-count .added-time').html(totalMinutes + ' minutes will be added');

        $('.js-btn-addon-continue:visible').html('Book Now');
      } else {
        scope.find('.js-addon-selected-count').fadeIn();
        scope.find('.js-addon-selected-count .count').html(addon_ids.length + ' Add-ons selected');
        scope.find('.js-addon-selected-count .added-time').html(totalMinutes + ' minutes will be added');

        $('.js-btn-addon-continue:visible').html('Book Now');
      }

      var submitBtn = scope.find('.js-btn-addon-submit-link');
      var originalUrl = submitBtn.data('url');

      if(addon_ids.length < 1) {
        // Keep a blank key so we know add-ons have been asked for
        var urlWithAddonIds = originalUrl + "&selected_addon_ids=" + JSON.stringify([]);
        submitBtn.prop('href', urlWithAddonIds);
      } else {
        var urlWithAddonIds = originalUrl + "&selected_addon_ids=" + JSON.stringify(addon_ids);
        submitBtn.prop('href', urlWithAddonIds);
      }

    });
  }
});
