$(document).on("turbolinks:load", function () {

  if ($('#js-select-packages').is('*')) {
    var packageSelectonRequired = $('#js-select-packages').data('selectionRequired');
    var submitButton = $('#js-add-membership-btn');
    var package_id = $('#js-package-id')

    if(packageSelectonRequired) {
      var submitBtnDefaultText = 'Select package to Continue';
      $('#btn-package-continue').prop('disabled', true);
    } else {
      var submitBtnDefaultText = 'Continue without Package';
    }

    $('#btn-package-continue').html(submitBtnDefaultText);

    $('.js-package-btn').on('click', function (event){
      event.preventDefault();

      if($(this).hasClass('btn-outline-dark')){
        // Clear all others since only one package can be selected
        $('.js-package-btn').removeClass('btn-primary');
        $('.js-package-btn').addClass('btn-outline-dark');

        // Active
        $(this).removeClass('btn-outline-dark');
        $(this).addClass('btn-primary');

        $('.js-package-selected-count').fadeIn();

        package_id.val($(this).data('package-id'));

        $('#btn-package-continue').html('Continue');
        $('#btn-package-continue').prop('disabled', false);
      } else {
        $(this).removeClass('btn-primary');
        $(this).addClass('btn-outline-dark');

        $('.js-package-selected-count').hide();
        package_id.val('');

        $('#btn-package-continue').html(submitBtnDefaultText);
        $('#btn-package-continue').prop('disabled', packageSelectonRequired);
      }
    });
  }

});
