var onmount = require("onmount");

$(document).on(
  "turbolinks:load ready show.bs closed.bs load page:change",
  function () {
    onmount();
  }
);

$(document).on("turbolinks:before-cache", () => {
  onmount.teardown();
});
