$(document).on("turbolinks:load", function () {
  if ($(".js-new-card-btn").is("*")) {
    $(".js-new-card-btn").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(".js-new-card-btn").slideUp("fast", function () {
        $("#stripe_elements").slideDown("fast");
      });
    });

    createStripeElementsForClientProfile();
  }
});

function createStripeElementsForClientProfile() {
  var elements = stripe.elements();
  var cardElement = elements.create("card", {
    style: {
      base: {
        color: "#191B1F",
        fontWeight: 500,
        fontFamily: "Inter UI, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",

        "::placeholder": {
          color: "#CFD7DF",
        },
      },
      invalid: {
        color: "#E25950",
      },
    },
  });

  cardElement.mount("#card-element");

  var cardButton = document.getElementById("card-button");
  var resultContainer = document.getElementById("card-result");

  var cardInput = document.getElementById("card-input");
  var cardSuccess = document.getElementById("card-success");

  cardButton.addEventListener("click", function (e) {
    changeLoadingState(true, "#card-button");
    console.log("Turning loading state on");
    setTimeout(function () {
      stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
        })
        .then(function (result) {
          if (result.error) {
            console.log("turning card-button off loading state");
            changeLoadingState(false, "#card-button");

            resultContainer.textContent = result.error.message;
          } else {
            $.ajax({
              url: window.addPaymentMethodUrl,
              type: "POST",
              beforeSend: function (xhr) {
                xhr.setRequestHeader(
                  "X-CSRF-Token",
                  localStorage.getItem("bp-x-csrf-token")
                );
              },
              data: { stripe_card: result.paymentMethod.id },
              dataType: "script",
              success: function () {
                // We can fail when we add a card that has insufficient_funds
                stripeSucceeded(cardInput, cardSuccess);
                setTimeout(
                  function () {
                    location.reload();
                  }.bind(this),
                  3000
                );
                return;
              },
              error: (response) => {
                changeLoadingState(false, "#card-button");
                if (
                  response.getResponseHeader("content-type").includes("json")
                ) {
                  resultContainer.textContent = JSON.parse(
                    response.responseText
                  ).error;
                } else {
                  resultContainer.textContent =
                    "We were unable to process adding this card. If this persists please contact support.";
                }
              },
            });
          }
        });
    }, 1000);
  });
}
