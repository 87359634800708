import Calendar from "tui-calendar"; /* ES6 */

onmount = require("onmount");
var moment = require("moment-timezone");
var calendar;

async function getData(url = "", data = {}) {
  url = url + "?" + new URLSearchParams(data);

  // Default options are marked with *
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

var refreshCalendar = function (calendar, start_at, end_at, location_id, user_id) {
  var events_url = $("#tui-calendar").data("url");

  if(user_id === undefined){
    var user_id = '';
  }

  getData(events_url, {
    start: start_at,
    end: end_at,
    location_id: location_id,
    user_id: user_id
  }).then((data) => {
    calendar.clear();
    calendar.createSchedules(data);
    $(".js-hide-me-after-loading").fadeOut("fast");
  });
};

var currentCalendarDate = (cal, format) => {
  var currentDate = moment([
    cal.getDate().getFullYear(),
    cal.getDate().getMonth(),
    cal.getDate().getDate(),
  ]);

  return currentDate.format(format);
};

var setRenderRangeText = (cal) => {
  var output = document.querySelector(".js-calendar-title");
  var options = cal.getOptions();
  var viewName = cal.getViewName();

  var html = [];
  if (viewName === "day") {
    html.push(currentCalendarDate(cal, "MMMM YYYY"));
  } else if (
    viewName === "month" &&
    (!options.month.visibleWeeksCount || options.month.visibleWeeksCount > 4)
  ) {
    html.push(currentCalendarDate(cal, "MMMM YYYY"));
  } else {
    html.push(currentCalendarDate(cal, "MMMM YYYY"));

  }
  output.innerHTML = html.join("");
};

/**
 * Get time template for time and all-day
 * @param {Schedule} schedule - schedule
 * @param {boolean} isAllDay - isAllDay or hasMultiDates
 * @returns {string}
 */
function getTimeTemplate(schedule, isAllDay) {
  var html = [];
  var start = moment(schedule.start.toUTCString());
  if (!isAllDay) {
    html.push("<strong>" + start.format("h:mma") + "</strong> ");
  }
  if (schedule.isPrivate) {
    html.push('<span class="calendar-font-icon ic-lock-b"></span>');
    html.push(" Private");
  } else {
    if (schedule.isReadOnly) {
      html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
    } else if (schedule.recurrenceRule) {
      html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
    } else if (schedule.attendees.length) {
      html.push('<span class="calendar-font-icon ic-user-b"></span>');
    } else if (schedule.location) {
      html.push('<span class="calendar-font-icon ic-location-b"></span>');
    }

    if (schedule.raw.bookingType == "group") {
      html.push('<i class="fas fa-users mx-1"></i>');
    } else if (schedule.raw.bookingType == "series") {
      html.push('<i class="fas fa-chevron-double-right mx-1"></i>');
    } else {
      html.push('<i class="fas fa-user mx-1"></i>');
    }

    html.push(" " + schedule.title);
  }

  return html.join("");
}

function bindLocationChange(calendar, start_at, end_at) {
  $("#location-id, #js-filter-by-user-id").on("change", function () {
    var location_id = $("#location-id").val();

    if($('#js-filter-by-user-id').is('*')) {
      var user_id = $('#js-filter-by-user-id').val();
      updateFilterByUserSelect();
    } else {
      var user_id = null;
    }


    $(".js-hide-me-after-loading").fadeIn("fast");

    refreshCalendar(calendar, start_at, end_at, location_id, user_id);
  });
}

function updateFilterByUserSelect(){
  var location_id = $("#location-id").val();
  $('#js-filter-by-user-id option').hide();
  $('#js-filter-by-user-id option:not([data-location-id]').show();
  $('#js-filter-by-user-id option[data-location-id="' + location_id + '"]').show();
}

$(document).on("turbolinks:load", function () {
  $('.open-customer-portral-iframe-modal').click(function(event){
    var data = $(this).data();
    var iframeSrc = data['modalsrc'];

    $('#booking-modal iframe').prop('src', iframeSrc);
    $('#booking-modal').modal();

    event.preventDefault();
  });

  $("#open-book-now-iframe-modal").click(function (event) {
    var data = $(this).data();
    var iframeSrc = data["modalsrc"] + "&location_id=" + data["locationid"];

    $("#booking-modal iframe").prop("src", iframeSrc);

    $("#booking-modal").modal();

    event.preventDefault();
  });

  $("#location-id").change(function () {
    $("#open-book-now-iframe-modal").data("locationid", $(this).val());
  });

  $("body").on("click", ".js-open-book-now-modal-from-cal", function (event) {
    if( $("#booking-modal iframe").is('*')){
      var iframeSrc = $(this).data("src");
      $("#booking-modal iframe").prop("src", iframeSrc);
      $("#booking-modal").modal();

      event.preventDefault();
    }
  });
});

onmount(
  ".js-tui-calendar",
  function () {
    var start_hour = parseInt($("#tui-calendar").data("start-hour"));
    var end_hour = parseInt($("#tui-calendar").data("end-hour"));
    if ($("select#location-id").is("*")) {
      var location_id = $("#location-id option:selected").val();
    } else {
      var location_id = $("#location-id").val();
    }

    end_hour = Math.min(...[end_hour, 24]);
    end_hour = Math.max(...[end_hour, 0]);

    start_hour = Math.min(...[start_hour, 24]);
    start_hour = Math.max(...[start_hour, 0]);

    if (window.innerWidth < 600) {
      var default_view = "day";
    } else {
      var default_view = "week";
    }

    calendar = new Calendar(".js-tui-calendar", {
      defaultView: default_view,
      week: {
        startDayOfWeek: 1,
        hourStart: start_hour,
        hourEnd: end_hour,
      },
      taskView: false,
      isReadOnly: true,
      useDetailPopup: true,
      template: {
        time: function (schedule) {
          return getTimeTemplate(schedule, false);
        },
      },
      timezone: {
        zones: [
          {
            timezoneName: this.dataset.jsCalendarTzinfoIdentifier,
            displayLabel: this.dataset.jsCalendarTzinfoIdentifier,
            tooltip: this.dataset.jsCalendarTzinfoIdentifier,
          },
        ],
        offsetCalculator: function (timezoneName, timestamp) {
          // matches 'getTimezoneOffset()' of Date API
          // e.g. +09:00 => -540, -04:00 => 240
          return moment.tz.zone(timezoneName).utcOffset(timestamp);
        },
      },
    });

    var setView = function (typeOfView) {
      var typeOfViewDisplayValue = { day: "Day", month: "Month", week: "Week" }[
        typeOfView
      ];

      var item = $(".js-calendar-intent-replace-me")[0];
      if (!item) {
        return;
      }

      item.innerHTML = typeOfViewDisplayValue;
      $(".js-calendar-intent-click.active").removeClass("active");
      var selector =
        '.js-calendar-intent-click[data-calendar-intent-value="' +
        typeOfView +
        '"]';
      $(selector).addClass("active");
      calendar.changeView(typeOfView);
      setRenderRangeText(calendar);
    };

    var refreshCalendarBasedOnVisibleRange = function (calendar) {
      var extract_date = function (tz_date) {
        return moment([
          tz_date.getFullYear(),
          tz_date.getMonth(),
          tz_date.getDate(),
        ]);
      };

      refreshCalendar(
        calendar,
        extract_date(calendar.getDateRangeStart()).toISOString(),
        extract_date(calendar.getDateRangeEnd()).add(1, "day").toISOString()
      );
    };

    var lastClickSchedule = null;
    calendar.on("clickSchedule", function (event) {
      var schedule = event.schedule;

      // focus the schedule
      if (lastClickSchedule) {
        calendar.updateSchedule(
          lastClickSchedule.id,
          lastClickSchedule.calendarId,
          {
            isFocused: false,
          }
        );
      }
      calendar.updateSchedule(schedule.id, schedule.calendarId, {
        isFocused: true,
      });

      lastClickSchedule = schedule;

      // open detail view
      // window.location.href = schedule.raw.visitable_url;
    });

    refreshCalendar(
      calendar,
      this.dataset.calendarStartAt,
      this.dataset.calendarEndAt,
      location_id
    );

    bindLocationChange(
      calendar,
      this.dataset.calendarStartAt,
      this.dataset.calendarEndAt
    );

    setRenderRangeText(calendar);

    var resizeCalendarFunction = () => {
      if (window.innerWidth < 575) {
        calendar.changeView("day");
      } else {
        const element = document.querySelector('.js-calendar-intent-replace-me');

        if(element !== null && element.textContent == "Month") {
          const viewName = "month";
        } else {
          const viewName = "week";
        }
        calendar.changeView(viewName);
      }
      calendar.render();
    };

    // Render a calendar when resizing a window.
    window.addEventListener("resize", resizeCalendarFunction);

    onmount(".js-calendar-date-increment-or-decrement", function () {
      $(this).unbind("click");
      $(this).on("click", () => {
        const sign = this.dataset.calendarSignValue;

        switch (sign) {
          case "1":
            calendar.next();
            break;
          case "-1":
            calendar.prev();
            break;
        }

        setRenderRangeText(calendar);
      });
    });

    onmount(".js-calendar-go-to-today", function () {
      $(this).on("click", () => {
        calendar.today();
        setRenderRangeText(calendar);
      });
    });

    onmount("#js-filter-by-user-id", function () {
      updateFilterByUserSelect();
    });

    onmount(".js-calendar-intent-click", function () {
      $(this).on("click", () => {
        const value = this.dataset.calendarIntentValue;
        const display_value = this.dataset.calendarIntentDisplayValue;

        setView(value, display_value);
        refreshCalendarBasedOnVisibleRange(calendar);
      });
    });

    $(document).one("turbolinks:before-cache", function () {
      // This fixes an issue where when we use the back button to return then
      // this is an empty div that is the size of the entire calendar. So, we
      // have this callback in the before-cache call to remove it before we
      // store the turbolinks cache. When we return, it's gone and the page
      // functions normally afaict.
      $(".tui-full-calendar-layout").remove();
    });
  },
  function () {
    calendar.destroy();
  }
);