import onmount from "onmount";
import SignaturePad from "signature_pad";

$(document).on("turbolinks:load", function () {
  var canvas = document.querySelectorAll("canvas");
  var sigPads = initializeCanvas();

  function initializeCanvas() {
    var sigPads = {};
    canvas.forEach(function (sig) {
      sigPads[$(sig).closest("form").data("form-key")] = new SignaturePad(sig);

      $(sig)
        .siblings(".clear")
        .click(function (event) {
          event.preventDefault();
          sigPads[$(sig).closest("form").data("form-key")].clear();
        });
    });

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    $(".nav-tabs")
      .find("a")
      .on("shown.bs.tab", function () {
        resizeCanvas();
      });

    return sigPads;
  }

  function resizeCanvas() {
    canvas.forEach(function (sig) {
      const width = sig.clientWidth;
      const height = sig.clientHeight;

      if (sig.width !== width || sig.height !== height) {
        sig.width = width;
        sig.height = height;
        return true;
      }
    });
  }

  function setSigPad(form) {
    if (canvas == null) {
      var sigPad = false;
    } else {
      var sigPad = sigPads[form.data("form-key")];
      form.find(".empty-signature").hide();
    }

    return sigPad;
  }

  function submitIntakeForm(form, sigPad, requestType) {
    form.find(".intake-form-alert").addClass("d-none");

    if (form[0].checkValidity() === false) {
      form.find(".intake-invalid-notification").removeClass("d-none");

      event.preventDefault();
      event.stopPropagation();
    } else {
      form.find(".submit").prop("disabled", true);
      form.find(".submit").html("Saving...");

      if (sigPad && sigPad.isEmpty()) {
        form.find(".empty-signature").show();

        setTimeout(function () {
          form.find(".submit").prop("disabled", false).html("Submit");
        }, 150);

        return false;
      } else {
        var submitURL = form.find(".submit").data("url");
        var redirectURL = form.find(".submit").data("redirect-url");
        var formData = new FormData(form[0]);

        if (sigPad) {
          var signatureB64 = sigPad.toDataURL();
          formData.append("signature", signatureB64);
        }

        var xhr = new XMLHttpRequest();
        xhr.open(requestType, submitURL);
        xhr.setRequestHeader("Accept", "text/javascript");
        xhr.send(formData);

        xhr.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            if (typeof redirectURL !== "undefined") {
              window.location.replace(redirectURL);
            } else {
              var response = this.response;
              var json = JSON.parse(response);
              window.location.replace(json.redirect_url);
            }
          } else if (this.readyState == 4) {
            form.find(".intake-form-alert").removeClass("d-none");
            form.find(".submit").prop("disabled", false);
            form.find(".submit").html("Submit");
          }
        };
      }
    }
  }

  if ($("#client-intake-forms").is("*")) {
    $("form").submit(function (event) {
      event.preventDefault();

      var form = $(this);
      var method = form.find(".submit").data("method");

      var sigPad = setSigPad(form);
      submitIntakeForm(form, sigPad, method);
    });
  }

  if ($("#client-forms-and-contracts").is("*")) {
    function handleSignatureBeforeSubmit(form, sigPad) {
      form.find(".intake-form-alert").addClass("d-none");

      if (sigPad && sigPad.isEmpty()) {
        form.find(".empty-signature").show();

        setTimeout(function () {
          form.find(".submit").prop("disabled", false).html("Submit");
        }, 150);

        return false;
      } else if (sigPad) {
        var signatureB64 = sigPad.toDataURL();
        $("#signature").val(signatureB64);
      }
    }

    $("form").submit(function (event) {
      var form = $(this);
      var sigPad = setSigPad(form);
      handleSignatureBeforeSubmit(form, sigPad);

      if ($(".empty-signature").is(":visible")) {
        setTimeout(function () {
          form.find(".submit").prop("disabled", false).html("Submit");
        }, 150);
        event.preventDefault();
      }
    });
  }
});

function resetFlatpickr() {
  // re-init flatpickr
  document.querySelectorAll(".flatpickr").forEach((item) => {
    if (item._flatpickr) {
      item._flatpickr.destroy();
    }
  });

  document.querySelectorAll(".flatpickr-required").forEach((item) => {
    if (item._flatpickr) {
      item._flatpickr.destroy();
    }
  });

  window.loadFlatpickr();
}

function validateVaccinationUploadFields(elem) {
  var container = $(elem);
  // console.log("container", container);
  // console.log("elem", elem);

  var vaccineExpiration = container.find(".vaccine-expiration");
  let dz = elem.querySelector('[data-controller="dropzone"]').dropzone;

  // console.log("vaccineExpiration", vaccineExpiration);

  // if a file has been uploaded then we want to set the vaccine expiry
  // date's flatpickr instances to be flatpickr-required
  if (vaccineExpiration.val() === "" && dz.files.length >= 1) {
    elem.querySelectorAll("input").forEach((item) => {
      // console.log("1 setting this input to required=required", item);
      item.required = "required";
    });

    vaccineExpiration.addClass("flatpickr-required");
    vaccineExpiration.removeClass("flatpickr");

    resetFlatpickr();
  }
  // if a expiry has been set then we want to set the hidden input field as
  // required date's flatpickr instances to be flatpickr-required
  if (dz.files.length == 0 && vaccineExpiration.val() !== "") {
    // console.log("expiry detected, setting everything required");
    elem.querySelectorAll("input").forEach((item) => {
      item.required = "required";
    });
  }

  // This means that they deselected everything, so we should remove required from both elements
  if (dz.files.length == 0 && vaccineExpiration.val() === "") {
    // console.log("Unsetting everything");
    // Now do the same for the file upload
    elem.querySelectorAll("input").forEach((item) => {
      // console.log("3 setting this input to required=", item);
      item.removeAttribute("required");
    });

    vaccineExpiration.addClass("flatpickr");
    vaccineExpiration.removeClass("flatpickr-required");

    resetFlatpickr();
  }
}

// onmount(".js-vaccine-validation-wrap", function () {
//   let wrapping = this;
//
//   this.addEventListener("change", (_item) => {
//     validateVaccinationUploadFields(wrapping);
//   });
//
//   let dropzone_instance = wrapping.querySelector(
//     "[data-controller='dropzone']"
//   ).dropzone;
//   dropzone_instance.on("complete", (_arg1) => {
//     validateVaccinationUploadFields(wrapping);
//   });
//   dropzone_instance.on("removedfile", (_arg1) => {
//     validateVaccinationUploadFields(wrapping);
//   });
// });
