import Swiper from "swiper/bundle";

// Node JS
require("trix");
require("onmount");
require("packs/javascripts/onmount");
require("flatpickr");
require("jquery-timepicker/jquery.timepicker");

// Application-wide JS
require("packs/javascripts/add_multiples");
require("packs/javascripts/plyr");
require("packs/javascripts/stripe");
require("packs/javascripts/draggable");
require("packs/javascripts/form_validation");
require("packs/javascripts/highlight_text");
require("packs/javascripts/iframeResizer.contentWindow");
require("packs/javascripts/pages/toggler");
require("packs/javascripts/datepicker");
require("packs/javascripts/notyf"); // window.notyf.error is called in app/views/customers/forms_and_contracts/new.html.slim - this is why notyf is needed in the customers js pack
require("packs/javascripts/stimulus");
require("packs/javascripts/rails_confirm");
require("packs/javascripts/text_truncate");
require("packs/javascripts/trix");
require("packs/javascripts/show_hide_password");

// Page specific JS
require("packs/javascripts/pages/customers/intake_forms");
require("packs/javascripts/pages/customers/client_profile_payment_methods");
require("packs/javascripts/pages/customers/online_booking_select_service");
require("packs/javascripts/pages/customers/online_booking_boarding");
require("packs/javascripts/pages/customers/online_booking_select_pet");
require("packs/javascripts/pages/customers/online_booking_select_package");
require("packs/javascripts/pages/customers/online_booking_select_addon");
require("packs/javascripts/pages/customers/online_booking_select_time");
require("packs/javascripts/pages/customers/online_booking_specific_booking");
require("packs/javascripts/pages/customers/calendar");
require("packs/javascripts/pages/online_booking");

$(document).on("turbolinks:load", function () {
  $(".js-course-unit-btn").click(function () {
    $(this).removeClass("btn btn-light");
    $(this).find(".js-lessons-list").slideDown("fast");
  });

  // make a table row or other element that links to another page
  $('body').on('click', '.clickable-row[data-link], .clickable[data-link]', function() {
    window.location = $(this).data("link");
  });

  // Add a sticky footer

  if ($(".sticky-footer").is("*")) {
    const resizeObserver = new ResizeObserver((entries) => {
      // Document resizes before the JS can recalc
      setTimeout(function () {
        $(window).trigger("resize");
      }, 500);
    });

    resizeObserver.observe(document.body);

    $(window).on("load resize scroll", function () {
      if ($(".sticky-anchor").is("*")) {
        var offsetHeight = $(".sticky-anchor").offset().top;
        var screenHeight = $(window).scrollTop() + $(window).height();

        if (offsetHeight > screenHeight) {
          $(".sticky-footer").css("position", "fixed");
          $(".sticky-footer").css("bottom", "0");
          $(".sticky-footer").css("left", "0");
          $(".sticky-footer").css("z-index", "999");
          $(".sticky-footer").addClass("card");
          $(".sticky-footer").addClass("w-100");
          $(".sticky-footer").addClass("mb-0");
        } else {
          $(".sticky-footer").css("position", "relative");
          $(".sticky-footer").css("bottom", "");
          $(".sticky-footer").css("left", "");
          $(".sticky-footer").css("z-index", "");
          $(".sticky-footer").removeClass("card");
          $(".sticky-footer").removeClass("w-100");
          $(".sticky-footer").removeClass("mb-0");
        }
      }
    });
  }
});

// Change bootstrap select input value when file selected
onmount(".custom-file-input", function () {
  document.querySelectorAll(".custom-file-input").forEach(bindChange);
  function bindChange(element) {
    element.addEventListener("change", function (e) {
      var fileName = e.target.files[0].name;
      var nextSibling = e.target.nextElementSibling;
      nextSibling.innerText = fileName;
    });
  }
});

onmount();

$(document).on("turbolinks:load ready", function () {
  $(window).trigger("load.bs.select.data-api");
});

$(document).on("turbolinks:before-cache", function () {
  for (const item of document.querySelectorAll(".selectpicker")) {
    const $picker = $(item);
    const picker = $picker.data("selectpicker");
    if (picker) {
      picker.destroy();
      $picker.addClass("selectpicker");
    }
  }
});

function stickyFooter() {}
