import Swiper from "swiper/bundle";
import moment from "moment";

$(document).on("turbolinks:load", function () {
  if ($(".calendar-container").is("*")) {
    window.calendarSwipers = {};

    var service_id = $("#booking_service_id").val();
    var location_id = $("#selected_location_id").val();

    load_calendar($(".calendar-container"), service_id, location_id);

    if ($(".calendar-container").data("display-type") == "swiper") {
      var swiperKey = service_id + " " + location_id;
      var calendarSwiper = window.calendarSwipers[swiperKey].swiper;
      calendarSwiper.update();

      $(".service-id-" + service_id)
        .addClass("active-time-slots")
        .slideDown("slow", function () {
          calendarSwiper.updateAutoHeight();
        });
    }

    $("body").on("click", ".time-slot a", function (event) {
      event.preventDefault();
      var time_slot = $(this);

      $("#booking-btn").prop("disabled", false);
      $("#booking-btn").html('<i class="fas fa-check mr-1"></i> Book Now');
      var active_slot = time_slot.parents("#client-booking-calendar").find(".active")
      
      if (active_slot) {
        active_slot.removeClass("active");
        active_slot.find('.js-booking-check-in').hide();
        active_slot.find('select').prop("disabled", true);
      }
        
      time_slot.addClass("active");
      time_slot.find('select').prop("disabled", false);
      time_slot.find('.js-booking-check-in').show();

      $('.selectpicker').selectpicker('refresh');
      $("#booking_start_at").val(time_slot.data("time"));
    });

    $("body").on("click", ".booking_slot a", function (event) {
      event.preventDefault();
      var booking_slot = $(this);

      $("#booking-btn").prop("disabled", false);
      $("#booking-btn").html('<i class="fas fa-check mr-1"></i> Book Now');
      booking_slot
        .parents(".calendar-container")
        .find(".active")
        .removeClass("active");
      booking_slot.addClass("active");

      $("#selected_booking_id").val(booking_slot.data("bookingId"));
    });
  }
});

function load_calendar(container, service_id, location_id) {
  var addon_ids = $("#new_booking").data("selected-addon-ids");

  if (addon_ids === undefined || addon_ids === "") {
    addon_ids = [];
  }

  addon_ids = JSON.stringify($("#new_booking").data("selected-addon-ids"));

  var new_days_url = $("#new_booking").data("new-days-url");
  var selected_user_id = $("#new_booking").data("selected-user-id");
  var series = $("#new_booking").data("series");
  var new_days_url_by_service_id =
    new_days_url +
    "?service_id=" +
    service_id +
    "&location_id=" +
    location_id +
    "&series=" +
    series +
    "&addon_ids=" +
    addon_ids +
    "&selected_user_id=" +
    selected_user_id;
  var end_range = container.data("end-range");

  if (container.data("display-type") == "swiper") {
    var swiperContainer = container.find(".swiper-container")[0];
    var swiperKey = service_id + " " + location_id;
    var group = container.data("group");
    var series = container.data("series");

    // Rewind - ReachEnd is called initially and we want it to start from today.
    var start_date = moment().subtract(14, "days");
    var end_date = moment().subtract(1, "days");
    var max_end_date = moment().add(1, "years");
    var swiperDelay = 0;

    window.calendarSwipers[swiperKey] = {
      swiper: null,
      start_date: start_date,
      end_date: end_date,
      max_end_date: max_end_date,
    };

    if (series) {
      var breakpoints = {
        500: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      };
    } else {
      var breakpoints = {
        500: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      };
    }

    window.calendarSwipers[swiperKey].swiper = new Swiper(swiperContainer, {
      autoHeight: false,
      slidesPerView: 1,
      threshold: 10,
      keyboard: {
        enabled: true,
      },
      breakpoints: breakpoints,
      navigation: {
        nextEl: ".swiper-button-next-" + service_id + "-" + location_id,
        prevEl: ".swiper-button-prev-" + service_id + "-" + location_id,
      },
      on: {
        reachEnd: function () {
          if ($(".calendar-container").data("loaded")) {
            console.log("Already loaded all events");
            return;
          }
          var start_date = window.calendarSwipers[swiperKey].start_date.add({
            days: 14,
          });
          var max_end_date = window.calendarSwipers[swiperKey].max_end_date;

          if (group || series) {
            var end_date = window.calendarSwipers[swiperKey].end_date.add({
              days: end_range,
            });
          } else {
            var end_date = window.calendarSwipers[swiperKey].end_date.add({
              days: 14,
            });
          }

          swiperDelay += 100;

          // Scroll out a maximum of 1 year from today
          if (end_date <= max_end_date) {
            $.ajax({
              url: new_days_url_by_service_id,
              type: "GET",
              data: {
                start_date: start_date.format(),
                end_date: end_date.format(),
                cache_buster: Math.random().toString(36).substring(7),
              },
              dataType: "script",
              success: function () {
                var swiper = window.calendarSwipers[swiperKey].swiper;
                swiper.update();

                bindWaitlistButton();
                $('.selectpicker').selectpicker('refresh');

                if (
                  $(swiper.slides[swiper.activeIndex]).hasClass(
                    "js-unavailable"
                  )
                ) {
                  swiper.slideNext(300);
                }

                if (swiper.isEnd && !group && !series) {
                  setTimeout(function () {
                    swiper.emit("reachEnd");
                  }, swiperDelay);
                }
              },
            });
          } else {
            // max_end_date of 1 year out has been reached;
            $("#js-loader-slide").remove();
          }
        },
      },
    });
  } else {
    var start_date, end_date, temp_end_date;
    var i = 0;
    start_date = end_date = temp_end_date = Date.now();
    end_date = moment(end_date).add(end_range, "days").format();

    console.log("Setup: " + start_date + " - to - " + end_date);

    do {
      if (i > 0) {
        start_date = moment(start_date).add(30, "days").format();
      } else {
        start_date = moment(start_date).format();
      }

      i++;

      if (moment(temp_end_date).diff(end_date, "days") > 30) {
        temp_end_date = moment(temp_end_date).add(30, "days").format();
      } else {
        temp_end_date = moment(end_date).format();
      }

      $.ajax({
        url: new_days_url_by_service_id,
        type: "GET",
        data: {
          start_date: start_date,
          end_date: temp_end_date,
          cache_buster: Math.random().toString(36).substring(7),
        },
        dataType: "script",
      });

      console.log(
        "While loop request: start_date: " +
          start_date +
          " - to - " +
          temp_end_date
      );
    } while (temp_end_date !== end_date);
  }
}

function bindWaitlistButton() {
  $(".js-waitlist-btn").on("click", function () {
    $("#waitlist-modal")
      .find("#waitlist_booking_id")
      .val($(this).data("booking"));
  });
}
