onmount = require("onmount");

const pluralize = (count, noun, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

// Example GET method implementation:
async function postData(url = "", data = {}) {
  url = url + "?" + new URLSearchParams(data);

  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

function calculatePetLimit(spotsAvailable) {
  if (
    spotsAvailable == undefined &&
    document.querySelector("#spots_available")
  ) {
    spotsAvailable = parseInt($("#spots_available").val());
  }

  $("#error-not-enough-availability").hide();
  let petSelectedCount = null;
  if (document.querySelector("#selected_pet_ids")) {
    petSelectedCount = JSON.parse($("#selected_pet_ids").val()).length;
  } else {
    petSelectedCount = 0;
  }

  petSelectedCount = petSelectedCount + $(".js-new-pet-row:visible").length;
  console.log(
    "spotsAvailable, petSelectedCount",
    spotsAvailable,
    petSelectedCount
  );

  if (petSelectedCount >= 1) {
    // if spotsAvailable is NaN, we depend on this falling through to enable the submit button
    if (petSelectedCount > spotsAvailable) {
      $("#error-not-enough-availability").show();
      $("button[type=submit]").prop("disabled", "disabled");
    } else {
      $("button[type=submit]").removeAttr("disabled");
    }
  } else {
    $("button[type=submit]").prop("disabled", "disabled");
  }

  if (!document.querySelector("#spots-available-text")) {
    return;
  }

  const remaining = spotsAvailable - petSelectedCount;
  console.log(`expected_pet_count: ${petSelectedCount}`);
  console.log(`remaining: ${remaining}`);

  if (remaining <= 0) {
    document.querySelector(".js-new-pet-button-container").style.display =
      "none";
  } else {
    document.querySelector(".js-new-pet-button-container").style.display =
      "block";
  }

  document.querySelector("#spots-available-text").innerHTML = `${pluralize(
    remaining,
    "spot"
  )} remaining`;

  if (window.bookingAttemptUpdateSelectedPetsUrl) {
    console.log(`expected_pet_count: ${petSelectedCount}`);
    let is_disabled = $("button[type=submit]").prop("disabled");

    if (!is_disabled) {
      $("button[type=submit]").prop("disabled", "disabled");
    }

    postData(window.bookingAttemptUpdateSelectedPetsUrl, {
      expected_pet_count: petSelectedCount,
    }).then(
      (server_data) => {
        if (!is_disabled) {
          $("button[type=submit]").removeAttr("disabled");
        }
      },
      (server_data) => {
        if (!is_disabled) {
          $("button[type=submit]").removeAttr("disabled");
        }
        // TODO: Add JS Exception notification here?
        // window.notyf.error(
        //   "Sorry, something went wrong. The data failed update."
        // );
      }
    );
  }
}

onmount("#edit-client-online-booking #selected_pet_ids", function () {
  $(this).on("change", function () {
    let pet_limit = this.dataset.petLimit;
    calculatePetLimit(pet_limit);
  });
  $(this).trigger("change");
});

onmount("#js-do-not-redeem-credits", function () {
  $(this).on("click", function (event) {
    $("input[type=password]").prop("required", false);
    $("#do_not_redeem_credits").val("true");
  });
});

onmount(".js-pet-limit-for-intake-forms", function (item) {
  $(this).on("click", (event) => {
    setTimeout(() => {
      let pet_limit = this.dataset.petLimit;
      calculatePetLimit(pet_limit);
    }, 250);
  });

  $(this).on("after-load", (event) => {
    let pet_limit = this.dataset.petLimit;
    calculatePetLimit(pet_limit);
  });

  setTimeout(() => {
    let pet_limit = this.dataset.petLimit;
    calculatePetLimit(pet_limit);
  }, 100);
});
